import HomeComments from '@/components/Home/new-home/HomeComments';
import HomeFeaturesSection from '@/components/Home/new-home/HomeFeaturesSection';
import HomeMegaHero from '@/components/Home/new-home/HomeMegaHero';
import HomeProductCategories from '@/components/Home/new-home/HomeProductCategories';
import HomeShopByModel from '@/components/Home/new-home/HomeShopByModel';
import YtVideos from '@/components/Home/new-home/YtVideos';
import CustomHead from '@/components/seo/CustomHead';
import OrganizationMarkup from '@/components/seo/markup-scripts/OrganizationMarkup';
import { Divider } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  getHomeComments,
  getHomeShopByModel,
  getHomeYtVideos,
} from 'app/api/home';
import dynamic from 'next/dynamic';

const OneTapGoogle = dynamic(() =>
  import('@/components/Login/SocialMedia/OneTapGoogle')
);
const HomeAffix = dynamic(
  () => import('@/components/Home/new-home/HomeAffix'),
  {
    ssr: false,
  }
);

function Home({ comments, ytVideos, seo, shopByModel }) {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <CustomHead
        selfTitle
        name='Nireeka - Buy Online Carbon-Fiber Smart Electric Bikes'
        description='Nireeka is an electric mobility company that redefines the biking experience using its solid, sleek, and gorgeous design and strives to make smarter e-bikes with better performance.'
        og={{
          image: 'https://nireeka.com/images/logos/white-bgBlack.jpg',
        }}
        seo={seo}
      />
      <HomeMegaHero />
      <Divider
        my={40}
        opacity={isMobile ? 1 : 0}
      />
      {shopByModel && <HomeShopByModel shopByModel={shopByModel} />}
      <Divider
        my={40}
        opacity={isMobile ? 1 : 0}
      />
      <HomeFeaturesSection />
      <Divider
        my={40}
        opacity={isMobile ? 1 : 0}
      />
      <HomeProductCategories />
      <Divider
        my={40}
        opacity={isMobile ? 1 : 0}
      />
      {comments && <HomeComments comments={comments} />}
      <Divider
        my={40}
        opacity={isMobile ? 1 : 0}
      />
      {ytVideos && <YtVideos ytVideos={ytVideos} />}
      <HomeAffix />
      {/* if user is not logged in and not loading then show google one tap login component */}
      <OneTapGoogle />
      <OrganizationMarkup />
    </>
  );
}

export const getStaticProps = async (context) => {
  /* The line is creating an array called `promises` that contains three promises. Each promise corresponds to a
  different API function. These promises will be used later to fetch data asynchronously from these API functions. */
  const promises = [
    getHomeComments(),
    getHomeYtVideos(),
    getHomeShopByModel(context.locale),
  ];

  let responses;
  try {
    /* `responses = await Promise.allSettled(promises);` is a line of code that uses the
    `Promise.allSettled()` method to asynchronously fetch data from multiple API functions. */
    responses = await Promise.allSettled(promises);

    /* This code is mapping over the array of responses from the `Promise.allSettled()`
    method. */
    responses = responses.map(({ status, value }) =>
      status === 'fulfilled' ? value : null
    );
  } catch (error) {
    console.log(error);
    k;
    return {
      notFound: true,
    };
  }

  return {
    props: {
      comments: responses[0],
      ytVideos: responses[1],
      shopByModel: responses[2],
      seo: {
        locale: context.locale,
        path: '/',
      },
    },
    revalidate: 60 * 60 * 24, // 1 day
  };
};

export default Home;
