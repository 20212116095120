import { Carousel } from '@mantine/carousel';
import { rem } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useState } from 'react';
import nireekaDarkLogo from '../../../public/images/logo_nireeka_dark.jpg';
import HomeMegaSlide from './HomeMegaSlide';
import { heroSlides } from './utils';

const HomeMegaHero = () => {
  const isDesktop = useMediaQuery(`(min-width: 1280px)`);
  const isGreaterThanTablet = useMediaQuery(`(min-width: 640px)`);
  const [embla, setEmbla] = useState(null);
  const [btnLink, setBtnLink] = useState(heroSlides[0].url || '/');

  const scrollPrev = useCallback(() => {
    if (embla) embla.scrollPrev();
  }, [embla]);

  const scrollNext = useCallback(() => {
    if (embla) embla.scrollNext();
  }, [embla]);

  embla?.on('scroll', () => {
    if (embla) {
      setBtnLink(heroSlides[embla.selectedScrollSnap()].url);
    }
  });

  embla?.on('init', () => {
    if (embla) {
      setBtnLink(heroSlides[0].url);
    }
  });

  return (
    <>
      <div className='flex flex-col items-center w-full pt-16 pb-0 mx-auto cursor-default sm:py-16 sm:pt-32 md:pt-16'>
        <div className='relative w-[140px] sm:w-[350px] h-10 hidden md:block'>
          <Image
            layout='fill'
            src={nireekaDarkLogo}
            alt='Nireeka Dark Logo'
            title='Nireeka Dark Logo'
            objectFit='contain'
          />
        </div>
        <span className='block mt-2 text-sm sm:text-xl !font-poppins sm:font-semibold font-bold px-2 text-center uppercase'>
          designed and engineered in oakville, canada
        </span>
        <span className='block !font-poppins text-gray-800 px-2 text-center text-xs sm:text-base'>
          Most powerful, most affordable, most unique
        </span>
      </div>
      <div className='relative w-full max-w-6xl mx-auto'>
        <Carousel
          mx='auto'
          slideGap='lg'
          slideSize='100%'
          withIndicators={isDesktop}
          controlSize={40}
          withControls={!!isGreaterThanTablet}
          getEmblaApi={setEmbla}
          styles={{
            indicator: {
              width: rem(6),
              height: rem(6),
              backgroundColor: '#000000 !important',
              '&[data-active]': {
                width: rem(10),
                height: rem(10),
                outlineStyle: 'solid !important',
                outlineWidth: '1px !important',
                outlineOffset: '3px !important',
                outlineColor: '#ef4444 !important',
              },
            },
            container: {
              height: 'auto !h-[80dvh]',
            },
            slide: {
              cursor: 'pointer',
              position: 'relative',
            },
            control: {
              backgroundColor: '#fff !important',
              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default',
              },
            },
          }}
          classNames={{
            controls: isDesktop && '!-left-12 !-right-12',
            indicators:
              '!translate-y-16 items-center gap-3 sm:!justify-start !w-5/6 !mx-auto',
          }}
        >
          {heroSlides.map((item, index) => (
            <Carousel.Slide key={index}>
              <HomeMegaSlide
                item={item}
                embla={embla}
                index={index}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
        <div className='flex items-center justify-between w-full col-span-2 px-6 mt-5 sm:hidden'>
          <Link
            href={btnLink}
            passHref
          >
            <a
              title={`Build your own ${
                heroSlides[embla?.selectedScrollSnap()]?.product
              }`}
              className='px-7 py-1.5 text-[10px] bg-gray-300 rounded-full'
            >
              Build your own
            </a>
          </Link>
          <div className='flex items-center gap-x-2'>
            <button
              className={`w-7 h-7 bg-gray-300 rounded-full flex items-center justify-center ${
                embla?.selectedScrollSnap() === 0 && 'opacity-0 cursor-default'
              }`}
              type='button'
              onClick={scrollPrev}
            >
              <IconArrowNarrowLeft
                stroke={1}
                size={16}
              />
            </button>
            <button
              className={`w-7 h-7 bg-gray-300 rounded-full flex items-center justify-center ${
                embla?.selectedScrollSnap() === heroSlides.length - 1 &&
                'opacity-0 cursor-default'
              }`}
              type='button'
              onClick={scrollNext}
            >
              <IconArrowNarrowRight
                stroke={1}
                size={16}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeMegaHero;
