import { Modal } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import batteryIcon from '../../../public/images/pro-home/Icons/battery.jpg';
import chronometerIcon from '../../../public/images/pro-home/Icons/chronometer-tick.jpg';
// import locationPinIcon from '../../../public/images/pro-home/Icons/location-pin.jpg';
import peakPowerIcon from '../../../public/images/pro-home/Icons/peak-power.jpg';

const HomeMegaSlide = ({ item, embla, index }) => {
  const router = useRouter();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [opened, { open, close }] = useDisclosure();

  /**
   * Get the unit of measurement based on the locale.
   */
  const unit = useMemo(() => {
    // If the locale is 'en-US', return 'imperial', otherwise return 'metric'.
    return router.locale === 'en-US' ? 'imperial' : 'metric';
  }, [router.locale]);

  return (
    <div className='grid grid-cols-12 px-3 select-none sm:pb-10 md:grid-rows-1 mt-7'>
      <Link
        href={item.url}
        passHref
        prefetch={false}
      >
        <a
          className='relative h-[270px] xs:h-[350px] sm:h-[80dvh] max-h-[650px] w-full md:col-span-9 col-span-12 row-span-3'
          title={item.product}
        >
          <Image
            src={item.image}
            alt={item.product}
            title={item.product}
            layout='fill'
            className='object-contain object-bottom md:object-center -z-50'
            priority
          />
        </a>
      </Link>
      <div className='grid flex-col w-full h-full grid-cols-2 col-span-12 grid-rows-1 row-span-3 pt-2 sm:row-span-2 sm:grid-rows-none md:pt-16 md:row-span-4 md:grid-cols-1 md:col-span-3 gap-y-8'>
        <div className='grid grid-cols-3 col-span-2 row-span-1 md:place-items-start place-items-center gap-y-4 sm:grid-cols-2 md:grid-cols-1 md:col-span-2 sm:col-span-1'>
          <div className='relative flex flex-col pl-3 text-base font-semibold sm:text-2xl'>
            <p className='self-start text-xl font-normal text-left capitalize sm:text-2xl'>
              {item.variation_details.power}
            </p>
            <div className='flex items-center ml-1'>
              <Image
                src={peakPowerIcon}
                alt='Peak Power Icon'
                title='Peak Power Icon'
                width={isMobile ? 21 : 36}
                height={isMobile ? 21 : 36}
              />
              <p className='text-[12px] sm:text-base ml-1 leading-3 capitalize whitespace-nowrap text-center sm:text-left'>
                peak power
              </p>
            </div>
          </div>
          <div className='relative flex flex-col pl-3 text-base font-semibold sm:text-2xl'>
            <p className='text-xl font-normal text-left capitalize sm:text-2xl '>
              {item.variation_details.battery}
            </p>
            <div className='flex items-center'>
              <Image
                src={batteryIcon}
                alt='Battery Icon'
                title='Battery Icon'
                width={isMobile ? 23 : 36}
                height={isMobile ? 23 : 36}
              />
              <p className='text-[12px] sm:text-base ml-1 leading-3 capitalize text-center sm:text-left'>
                battery
              </p>
            </div>
          </div>
          {/* <div className='relative flex flex-col items-center pl-3 ml-1 text-base font-semibold sm:text-2xl whitespace-nowrap'>
            <Image
              src={locationPinIcon}
              alt='Location Pin Icon'
              title='Location Pin Icon'
              width={isMobile ? 20 : 26}
              height={isMobile ? 30 : 36}
            />
            <div className='ml-0 sm:ml-3'>
              <p className='text-center capitalize sm:text-left'>
                {item.variation_details.range[unit]}
              </p>
              <p className='text-[11px] leading-3 tracking-[0.3em] capitalize text-center sm:text-left'>
                range
              </p>
            </div>
          </div> */}
          <div className='relative flex flex-col pl-3 text-base font-semibold sm:text-2xl'>
            <p className='self-start text-xl font-normal text-left sm:text-2xl'>
              {item.variation_details.max_speed[unit]}
            </p>
            <div className='flex items-center'>
              <Image
                src={chronometerIcon}
                alt='chronometer tick'
                title='chronometer tick'
                width={isMobile ? 24 : 40}
                height={isMobile ? 21.5 : 36}
              />
              <p className='text-[12px] sm:text-base ml-1 leading-3 capitalize text-center sm:text-left'>
                max speed
              </p>
            </div>
          </div>
        </div>
        <div
          className='relative hidden w-full h-full col-span-2 row-span-2 overflow-hidden rounded-md sm:block sm:row-span-1 sm:col-span-1'
          onClick={open}
        >
          <Image
            src={item.ytImageThumbnail}
            alt={item.product}
            title={item.product}
            layout='fill'
            className='object-contain object-center -z-50 brightness-50'
            priority
          />
          <div className='absolute p-3 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full bg-opacity-60 top-1/2 left-1/2'>
            <IconPlayerPlayFilled className='text-white' />
          </div>
        </div>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        centered
        zIndex={1001}
        withCloseButton={false}
        size={isMobile ? '100%' : '80%'}
        radius='lg'
        classNames={{
          content: '!max-h-none !bg-transparent',
          inner: `!overflow-y-auto !p-0`,
          body: ' !flex !justify-center !items-center !p-0',
          title: '!text-lg !font-bold',
          close: '!w-10 !h-10 [&_svg]:!w-7 [&_svg]:!h-7',
          header: '!block !relative',
        }}
      >
        <div className='flex items-center justify-center w-full'>
          <iframe
            className='w-full h-full aspect-video'
            src={`https://www.youtube.com/embed/${item.videoId}?autoplay=1&amp;modestbranding=1&amp`}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
      </Modal>
    </div>
  );
};

export default HomeMegaSlide;
