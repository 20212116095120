import { Carousel } from '@mantine/carousel';
import { Modal } from '@mantine/core';
import { useDisclosure, useHover, useMediaQuery } from '@mantine/hooks';
import { formatNumber } from 'functions/convertors';
import Image from 'next/image';

function Card(video) {
  const { hovered, ref } = useHover();
  const mobile = useMediaQuery(`(max-width: 767px)`);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <div
        className='flex flex-col min-h-[300px] max-h-[500px] h-full overflow-auto cursor-pointer justify-stretch transition relative'
        onClick={open}
      >
        <div className='relative w-full overflow-hidden aspect-video rounded-xl'>
          <Image
            src={video.snippet.thumbnails.standard.url}
            alt={video.snippet.title}
            layout='fill'
            sizes='(max-width: 767px) 95vw, (max-width: 1023px) 45vw, (max-width: 1140px) 30vw, 320px'
            objectFit='cover'
            className={`${hovered && 'scale-110'} transition`}
          />
        </div>
        <div className='grid grid-cols-3 px-3 pt-3'>
          <p className='col-span-2 font-medium font-poppins'>
            {video.snippet.title}
          </p>
          <p className='text-sm text-right text-gray-700 font-OpenSans'>
            {video.snippet.channelTitle}
            <br />
            {formatNumber(+video.statistics.viewCount)} View
            <br />
            {formatNumber(+video.statistics.commentCount)} Comments
          </p>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        centered
        zIndex={1001}
        withCloseButton={false}
        size={mobile ? '100%' : '80%'}
        classNames={{
          content: '!max-h-none !bg-transparent',
          inner: `!overflow-y-auto !p-0`,
          body: ' !flex !justify-center !items-center !p-0',
          title: '!text-lg !font-bold',
          close: '!w-10 !h-10 [&_svg]:!w-7 [&_svg]:!h-7',
          header: '!block !relative',
        }}
        radius='lg'
      >
        <div className='flex items-center justify-center w-full'>
          <iframe
            className='w-full h-full aspect-video'
            src={`https://www.youtube.com/embed/${video.id}?autoplay=1&amp;modestbranding=1&amp`}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </div>
      </Modal>
    </>
  );
}

const YtVideos = ({ ytVideos }) => {
  const mobile = useMediaQuery(`(max-width: 767px)`);
  const tablet = useMediaQuery(`(max-width: 1024px)`);
  const desktop = useMediaQuery(`(min-width: 1280px)`);

  const slides = ytVideos.map((item, index) => (
    <Carousel.Slide key={index}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <div className='flex flex-col items-center gap-3 sm:mt-20'>
      <h2 className='self-start max-w-md px-5 mb-5 text-lg font-semibold sm:text-center sm:self-center sm:text-4xl font-poppins'>
        Latest Nireeka Youtube Videos
      </h2>
      <Carousel
        slideSize={mobile ? '85%' : tablet ? '42%' : '40%'}
        slideGap='xl'
        align='start'
        withCloseButton={false}
        slidesToScroll={1}
        maw={1140}
        mx='auto'
        containScroll='trimSnaps'
        withControls={!mobile}
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
        controlSize={40}
        classNames={{
          controls: desktop && '!-left-12 !-right-12',
          root: 'sm:!mb-36 !mb-16 !w-full !px-5',
          control: '!bg-white',
        }}
      >
        {slides}
      </Carousel>
    </div>
  );
};

export default YtVideos;
