import SecondaryButton from '@/components/Atoms/buttons/SecondaryButton';
import { Carousel } from '@mantine/carousel';
import { Modal } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import Image from 'next/image';
import Link from 'next/link';
import { homeProductCategories } from './utils';

const HomeProductCategories = () => {
  const [opened, { open, close }] = useDisclosure();
  const mobile = useMediaQuery(`(max-width: 767px)`);
  const tablet = useMediaQuery(`(max-width: 1024px)`);
  const desktop = useMediaQuery(`(min-width: 1280px)`);

  return (
    <div className='w-full max-w-6xl mx-auto '>
      <h3 className='self-start w-full max-w-md px-5 my-5 text-lg font-semibold sm:mx-auto sm:text-center sm:self-center sm:text-4xl font-poppins'>
        Shop by category
      </h3>
      <Carousel
        slideSize={mobile ? '80%' : tablet ? '42%' : '33%'}
        slideGap='xs'
        align='start'
        slidesToScroll={1}
        maw={1140}
        controlSize={40}
        mx='auto'
        containScroll='trimSnaps'
        withControls={false}
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
        classNames={{
          controls: desktop && '!-left-12 !-right-12',
          root: '!w-full !px-5',
          control: '!bg-white',
        }}
      >
        {homeProductCategories.map((category, index) => (
          <Carousel.Slide key={index}>
            <Link
              key={index}
              href={category.link}
              passHref
              prefetch={false}
            >
              <a
                className='relative block w-full lg:max-w-[350px] aspect-video rounded-xl overflow-hidden'
                title={`Explore ${category.title}`}
                // style={{
                //   clipPath: 'polygon(0 0, 100% 0, 100% 100%, 9% 100%, 0 91%)',
                // }}
              >
                <Image
                  src={category.imageUrl}
                  alt={category.title}
                  title={category.title}
                  layout='fill'
                  objectFit='cover'
                  className='brightness-[0.4]'
                />
                <div className='absolute flex flex-col justify-start left-4 bottom-4'>
                  <p className='ml-2 text-xl text-white font- sm:text-2xl'>
                    {category.title}
                  </p>
                  <Link
                    href={category.link}
                    passHref
                    prefetch={false}
                  >
                    <a
                      className='px-7 py-1.5 mt-[6px] text-[9px] bg-white w-fit relative rounded-full font-poppins'
                      title={`Explore ${category.title}`}
                    >
                      View products
                    </a>
                  </Link>
                </div>
              </a>
            </Link>
          </Carousel.Slide>
        ))}
      </Carousel>
      <div className='flex flex-col items-center justify-center w-full mt-8 sm:mt-14 '>
        {/* <p className='text-lg font-semibold'>Watch the comparison video</p> */}
        {mobile ? (
          <button
            className='px-7 py-1.5 text-[10px] bg-gray-300 rounded-full'
            onClick={open}
          >
            Watch the comparison video
          </button>
        ) : (
          <SecondaryButton
            className='sm:!mb-32 mt-3 '
            onClick={open}
            ariaLabel='Open modal with youtube video'
          >
            Watch the comparison video
          </SecondaryButton>
        )}
        <Modal
          opened={opened}
          onClose={close}
          centered
          zIndex={1001}
          withCloseButton={false}
          size={mobile ? '100%' : '80%'}
          radius='lg'
          classNames={{
            content: '!max-h-none !bg-transparent',
            inner: `!overflow-y-auto !p-0`,
            body: ' !flex !justify-center !items-center !p-0',
            title: '!text-lg !font-bold',
            close: '!w-10 !h-10 [&_svg]:!w-7 [&_svg]:!h-7',
            header: '!block !relative',
          }}
        >
          <div className='flex items-center justify-center w-full'>
            <iframe
              className='w-full h-full aspect-video'
              src={`https://www.youtube.com/embed/-Lw70w0drbc?autoplay=1&amp;modestbranding=1&amp`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default HomeProductCategories;
