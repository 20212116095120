// import Hero from '@/components/ProHome/Hero';
import { Carousel } from '@mantine/carousel';
import { Modal } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import Image from 'next/image';
import Link from 'next/link';
import StarRatings from 'react-star-ratings';

function Card(post) {
  const mobile = useMediaQuery(`(max-width: 767px)`);
  const [opened, { open, close }] = useDisclosure(false);

  console.log(post);

  return (
    <>
      <div className='flex flex-col'>
        <div className='grid grid-cols-12 border border-gray-300 max-h-36 sm:max-h-44 rounded-xl'>
          <div
            className='relative w-full col-span-5 cursor-pointer h-36 sm:h-44'
            onClick={open}
          >
            <Image
              src={post.files[0].original}
              alt={post.product.title}
              title={post.product.title}
              layout='fill'
              sizes='(max-width: 767px) 95vw, (max-width: 1023px) 45vw, (max-width: 1140px) 30vw, 320px'
              objectFit='cover'
              className='overflow-hidden rounded-s-xl'
            />
          </div>
          <div className='col-span-7 py-2 pl-2 pr-3'>
            <div className='flex items-center justify-between'>
              <span className='text-[10px] sm:text-xs'>
                {post.user.full_name}
              </span>
              <div className='relative w-3 h-3 overflow-hidden sm:w-5 sm:h-5'>
                <Image
                  src={post.user.country.image_path}
                  alt={`${post.user.country.title} Flag`}
                  title={`${post.user.country.title} Flag`}
                  layout='fill'
                  objectFit='cover'
                  className='overflow-hidden rounded-lg'
                />
              </div>
            </div>
            <p className='py-2 text-xs select-none sm:text-sm'>
              {post.body.length > 160
                ? post.body.substring(0, 160) + '...'
                : post.body}
            </p>
          </div>
        </div>
        <div className='flex flex-col pt-2'>
          <div className='flex items-center justify-between w-full gap-2'>
            <p className='text-xs sm:text-sm'>By {post.user.full_name}</p>
            <div className='text-[10px] text-gray-500 sm:text-xs'>
              <p>{post.created_at}</p>
            </div>
          </div>
          <div className='flex items-start justify-between w-full gap-2'>
            <p className='text-xs sm:text-sm'>{post.user.country.title}</p>
            <div className='flex flex-col items-end'>
              <StarRatings
                starDimension='0.8rem'
                numberOfStars={5}
                starEmptyColor='#d1d5db'
                starSpacing='0.01rem'
                svgIconViewBox='0 0 20 20'
                svgIconPath='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z'
                rating={post.rating}
                starRatedColor='#facc15'
              />
              <p className='text-xs text-gray-500 sm:text-sm'>
                {post.rating}/5
              </p>
            </div>
          </div>
          <Link
            href={post.product.url}
            passHref
            prefetch={false}
          >
            <a
              className='flex items-center gap-1 mt-1 text-xs text-gray-800 transition sm:text-sm hover:text-black'
              title={post.product.title}
            >
              <span className='flex items-center whitespace-nowrap'>
                {post.product.title}
                {/* <IconExternalLink className='w-3 h-3' /> */}
              </span>
            </a>
          </Link>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        zIndex={1001}
        withCloseButton={false}
        size={mobile ? '100%' : '80%'}
        classNames={{
          content: '!max-h-none !bg-transparent',
          inner: `!overflow-y-auto !p-0`,
          body: '!h-[100vh] !flex !justify-center !items-center',
          title: '!text-lg !font-bold',
          close: '!w-10 !h-10 [&_svg]:!w-7 [&_svg]:!h-7',
          header: '!block !relative',
        }}
        radius='md'
      >
        <Image
          src={post.files[0].original}
          alt={post.product.title}
          title={post.product.title}
          layout='fill'
          objectFit='contain'
          onClick={close}
        />
      </Modal>
    </>
  );
}

const HomeComments = ({ comments }) => {
  const mobile = useMediaQuery(`(max-width: 767px)`);
  const tablet = useMediaQuery(`(max-width: 1024px)`);
  const desktop = useMediaQuery(`(min-width: 1280px)`);

  const slides = comments.map((item, index) => (
    <Carousel.Slide key={index}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <div className='flex flex-col items-center gap-3 sm:mt-20'>
      <h3 className='self-start max-w-md px-5 mb-5 text-lg font-semibold sm:text-center sm:self-center sm:text-4xl font-poppins'>
        Latest Reviews By Customers
      </h3>
      <Carousel
        slideSize={mobile ? '85%' : tablet ? '42%' : '40%'}
        slideGap={mobile ? 'sm' : 'xl'}
        align='start'
        slidesToScroll={1}
        maw={1140}
        controlSize={40}
        mx='auto'
        containScroll='trimSnaps'
        withControls={!mobile}
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
        classNames={{
          controls: desktop && '!-left-12 !-right-12',
          root: 'sm:!mb-32 !w-full !px-5',
          control: '!bg-white',
        }}
      >
        {slides}
      </Carousel>
    </div>
  );
};

export default HomeComments;
