import { useMediaQuery } from '@mantine/hooks';
import { IconHome, IconSettingsCheck } from '@tabler/icons-react';
import Image from 'next/image';
import canadaFlagIcon from '../../../public/images/pro-home/Icons/canada-flag-icon.png';

const HomeFeaturesSection = () => {
  const isMobile = useMediaQuery(`(max-width: 767px)`);

  return (
    <div className='grid items-start w-full max-w-5xl grid-cols-2 gap-10 px-4 mx-auto select-none md:grid-cols-3 sm:mb-32 sm:gap-y-24'>
      <div className='flex flex-col items-center justify-center col-span-2 md:col-span-1'>
        <Image
          src={canadaFlagIcon}
          alt='Canada Flag icon'
          title='Canada Flag icon'
          width={isMobile ? 30 : 50}
          height={isMobile ? 30 : 50}
        />
        <p className='mt-3 text-sm font-bold text-center capitalize sm:text-xl sm:mt-5 sm:mb-5'>
          proudly canadian
        </p>
        <p className='text-[10px] sm:text-base font-light text-center text-gray-500'>
          Operated since 2018
        </p>
      </div>
      <div className='flex flex-col items-center justify-center text-center -translate-y-1 sm:-translate-y-0'>
        <IconHome
          className='w-8 h-8 sm:w-14 sm:h-14'
          strokeWidth={0.8}
        />
        <p className='mt-3 text-sm font-bold text-center capitalize sm:text-xl sm:mt-5 sm:mb-5'>
          Home Delivery
        </p>
        <p className='text-[10px] sm:text-base font-light text-center text-gray-500'>
          Delivery or Curbside pick-up
        </p>
      </div>
      <div className='flex flex-col items-center justify-center text-center'>
        <IconSettingsCheck
          className='w-8 h-8 sm:w-14 sm:h-14'
          strokeWidth={0.8}
        />
        <p className='mt-3 text-sm font-bold text-center capitalize sm:text-xl sm:mt-5 sm:mb-5'>
          Custom Orders
        </p>
        <p className='text-[10px] sm:text-base font-light text-center text-gray-500'>
          Build your own dream bike online
        </p>
      </div>
    </div>
  );
};

export default HomeFeaturesSection;
