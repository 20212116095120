import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import Image from 'next/image';
import Link from 'next/link';
import testImg from '../../../public/images/pro-home/test.png';

const HomeShopByModel = ({ shopByModel }) => {
  const mobile = useMediaQuery(`(max-width: 767px)`);
  const tablet = useMediaQuery(`(max-width: 1024px)`);
  const desktop = useMediaQuery(`(min-width: 1280px)`);

  console.log(shopByModel);

  return (
    <div className='w-full max-w-6xl mx-auto sm:mt-32'>
      <h3 className='self-start w-full max-w-md px-5 my-5 text-lg font-semibold sm:mx-auto sm:text-center sm:self-center sm:text-4xl font-poppins'>
        Shop by model
      </h3>
      <Carousel
        slideSize={mobile ? '80%' : tablet ? '42%' : '38%'}
        slideGap='xs'
        align='start'
        slidesToScroll={1}
        maw={1140}
        controlSize={40}
        mx='auto'
        containScroll='trimSnaps'
        withControls={!mobile}
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
        classNames={{
          controls: desktop && '!-left-12 !-right-12',
          root: 'sm:!mb-32 !w-full !px-5',
          control: '!bg-white',
        }}
      >
        {shopByModel.map((model, index) => (
          <Carousel.Slide key={index}>
            <Link
              key={index}
              href={model.url}
              passHref
              prefetch={false}
            >
              <a
                className='relative block w-full max-w-[450px] lg:max-w-[500px] aspect-square rounded-xl overflow-hidden text-white'
                title={`See ${model.title} ${model.variation}`}
              >
                <Image
                  src={model.image || testImg}
                  alt={`${model.title} ${model.variation}`}
                  title={`See ${model.title} ${model.variation}`}
                  layout='fill'
                  objectFit='cover'
                />
                <div className='absolute top-4 inset-x-4 '>
                  <p className='text-[11px] sm:text-sm lg:text-base text-red-500'>
                    {model.class}
                  </p>
                  <p className='mt-1 text-[12px] sm:text-base lg:text-xl font-light'>
                    {model.title} {model.variation}
                  </p>
                  <p className='mt-1 text-[10px] sm:text-sm max-w-full font-light'>
                    {model.description}
                  </p>
                </div>
                <div className='absolute top-4 right-5'>
                  <p className='lg:text-xl'>{model.price}</p>
                </div>
                <Link
                  href={model.url}
                  passHref
                  prefetch={false}
                >
                  <a
                    className='px-8 py-1.5 mt-[6px] text-[9px] sm:text-sm bg-white w-fit rounded-full font-poppins absolute left-[18px] bottom-[14px] text-black '
                    aria-label={`See ${model.title} ${model.variation}`}
                  >
                    More details
                  </a>
                </Link>
              </a>
            </Link>
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeShopByModel;

const ShopByModelSlide = () => {
  return <div></div>;
};
