import nireekaMega from '../../../public/images/pro-home/nireeka-mega-black.png';
import nireekaMegaSlider from '../../../public/images/pro-home/nireeka-mega-slider.png';
import nireekaPrime from '../../../public/images/pro-home/nireeka-prime.png';
import nireekaRevenantSlider from '../../../public/images/pro-home/nireeka-revenant-slider.png';
import nireekaRevenant from '../../../public/images/pro-home/nireeka-revenant.png';

export const heroSlides = [
  {
    product: 'Nireeka Revenant',
    url: '/configurator/nireeka-revenant-1000',
    image: nireekaRevenantSlider,
    ytImageThumbnail: 'https://i.ytimg.com/vi/BCJ1ozXVEs8/maxresdefault.jpg',
    videoId: 'BCJ1ozXVEs8',
    variation_details: {
      name: '1000',
      max_speed: {
        metric: '57 Km/h',
        imperial: '35 Mph',
      },
      range: {
        metric: '80 km',
        imperial: '50 miles',
      },
      power: '1300W',
      battery: '840Wh',
    },
  },
  {
    product: 'Nireeka Mega',
    url: '/configurator/nireeka-mega-1500',
    image: nireekaMegaSlider,
    ytImageThumbnail: 'https://i.ytimg.com/vi/cwdL4whOdWY/maxresdefault.jpg',
    videoId: 'cwdL4whOdWY',
    variation_details: {
      name: '1000',
      max_speed: {
        metric: '60 Km/h',
        imperial: '37 Mph',
      },
      range: {
        metric: '83 km',
        imperial: '52 miles',
      },
      power: '1500W',
      battery: '840Wh',
    },
  },
];

export const homeProductCategories = [
  {
    title: 'All Bikes',
    imageUrl: nireekaMega,
    buttonText: 'View Products',
    link: '/bikes',
  },
  {
    title: 'Fat Bikes',
    imageUrl: nireekaPrime,
    buttonText: 'View Products',
    link: '/fat-bikes',
  },
  {
    title: 'Mountain Bikes',
    imageUrl: nireekaRevenant,
    buttonText: 'View Products',
    link: '/mountain-bikes',
  },
];
