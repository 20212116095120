import Head from 'next/head';
import { useRouter } from 'next/router';

const CustomHead = ({ images, name, description, selfTitle, og, seo }) => {
  const router = useRouter();
  const customName = name ? `Nireeka | ${name} is best bike` : 'Nireeka'; // Create a custom name based on the provided name
  const customTitle = selfTitle ? `${name}` : customName; // Create a custom title based on the provided name or the custom name

  let path = router.asPath; // Get the current path from the router object

  // If the last character of the path is "/", remove it
  if (path.charAt(path.length - 1) === '/') path = path.slice(0, -1);

  // Decode the path
  let pageUrl = decodeURIComponent(
    `https://nireeka.com/${router.locale || seo?.locale}${path || seo?.path}`
  );

  // if last character of pageUrl was "/" then remove it
  if (pageUrl.charAt(pageUrl.length - 1) === '/')
    pageUrl = pageUrl.slice(0, -1);

  const ogImage = og?.image || images?.[0];

  return (
    <>
      <Head>
        <title>{customTitle}</title>
        <link
          rel='canonical'
          key='canonical'
          href={pageUrl}
        />
        <meta
          name='description'
          key='description'
          content={description}
        />
        <meta
          name='twitter:card'
          content={ogImage ? 'summary_large_image' : 'summary'}
        />
        <meta
          name='twitter:url'
          key='twitterUrl'
          content={pageUrl}
        />
        <meta
          name='twitter:title'
          key='twitterTitle'
          content={customTitle}
        />
        <meta
          name='twitter:description'
          key='twitterDescription'
          content={description}
        />
        {ogImage && (
          <>
            <meta
              name='twitter:image'
              key='twitterImage'
              content={ogImage}
            />
            <meta
              property='og:image'
              key='openGraphImage'
              content={ogImage}
            />
          </>
        )}
        <meta
          property='og:type'
          key='openGraphType'
          content={og?.type || 'website'}
        />
        <meta
          property='og:url'
          key='openGraphUrl'
          content={pageUrl}
        />
        <meta
          property='og:title'
          key='openGraphTitle'
          content={customTitle}
        />
        <meta
          property='og:description'
          key='openGraphDescription'
          content={description}
        />
      </Head>
    </>
  );
};

export default CustomHead;
