import Script from 'next/script';

const OrganizationMarkup = () => {
  return (
    <Script
      id='schema-org-corporation'
      strategy='beforeInteractive'
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: `{"@context":"https://schema.org","@type":"Corporation","name":"Nireeka","description":"Nireeka is an electric mobility company that redefines the biking experience using its solid, sleek, and gorgeous design and strives to make smarter e-bikes with better performance.","email":"info@nireeka.com","alternateName":"Nireeka Technologies Inc","url":"https://nireeka.com","logo":"https://nireeka.com/images/logos/white-bgBlack.jpg","address":{"@type":"PostalAddress","streetAddress":"790 Redwood Square","addressLocality":"Oakville","postalCode":"L6L6N3","areaServed":{"@context":"https://schema.org","@type":"Place","geo":{"@type":"GeoCoordinates","latitude":"43.43441","longitude":"-79.70685"},"hasMap":"https://maps.app.goo.gl/URXqu4mup43cwSzTA"},"addressCountry":{"@type":"Country","name":"CA"}},"contactPoint":{"@type":"ContactPoint","telephone":"+12134447814","contactType":"customer service","areaServed":["US","CA"],"availableLanguage":"en"},"sameAs":["https://www.facebook.com/nireeka","https://twitter.com/nireeka1","https://instagram.com/nireeka.official","https://www.youtube.com/nireeka","https://www.linkedin.com/company/nireeka"]}`,
      }}
    />
  );
};

export default OrganizationMarkup;
